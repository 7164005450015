.dark-switch .MuiSwitch-track {
    background-color: #fff;
    opacity: 0.8;
}
.dark-switch .Mui-checked  .MuiSwitch-thumb{
    color:#20808d;
} 
.dark-switch .Mui-checked+.MuiSwitch-track {
    background-color: #20808d!important;
}

.dark-mode-form input{
    outline: none;
    color: white;
}
.dark-mode-form .MuiOutlinedInput-root fieldset{
    border-color: white;
}
.dark-mode-form .MuiOutlinedInput-root:hover fieldset{
    border-color: white;
}

.dark-mode-form label,.dark-mode-form label.mui-focused{
    color: white!important;
}

.dark-mode-form .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #20808d;
}

.dark-mode-small-nav > div > div{
 background-color: #20808d;
}
.dark-mode-small-nav svg,.dark-mode-small-nav span{
    color: white;
}
.form-container .inputForm .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
    height: auto;
}

.form-container .inputForm .MuiFormControl-root .MuiFormLabel-filled{
   color: white!important;
}
.form-container .inputForm .MuiFormControl-root .MuiFormLabel-filled + .MuiInputBase-root,.form-container .MuiFormControl-root .MuiFormLabel-filled + .MuiInputBase-root {
    padding: 8px 5px;
}
.form-container .inputForm .MuiFormControl-root .MuiFormLabel-filled + .MuiInputBase-root input,.form-container .MuiFormControl-root .MuiFormLabel-filled + .MuiInputBase-root input{
    padding: 8.5px 5px;
}